export default function multivariant() {
    var domains = [];
    var found_product_with_different_images = false;
    var found_product_with_swatch_options = false;
    var found_product_with_more_options = false;
    var found_product_with_stock_out_option = false;

    $('.shopify_domain').each(async function(index, obj) {
        let shopifyDomain = $(this);
        // added attribute named run-script to help determine to run the script
        if (obj.dataset.runScript == 'false') {
            return;
        }
        var domain = $(obj).data('domain');
        var app_name = $(obj).data('app_name');
        var is_vdk = $(obj).hasClass('VDK');
        if ($.inArray(domain, domains) < 0 || is_vdk) {
            var page = 1;
            // construct collection url and fetch products in batch/page wise
            var collection_url = 'https://' + domain + '/collections/all/products.json?page=';
            // if different domain, then search all products again
            // we are doing this because I dont want to search for VDK in every domains
            // search for VDK products only if store has installed VDK
            if ($.inArray(domain, domains) < 0) {
                found_product_with_different_images = false;
                found_product_with_swatch_options = false;
                found_product_with_more_options = false;
                found_product_with_stock_out_option = false;
            }
            var found_vdk_product = false;
            // add domain
            domains.push(domain)

            while (true) {

                let url = collection_url + page

                let response = await fetch(url);

                if (response.ok) { // if HTTP-status is 200-299
                    // get the response body (the method explained below)
                    let json = await response.json();

                    let products = json.products

                    console.log(products);

                    if (products.length > 0) {

                        // loop products to find multivariant product
                        for (let i in products) {

                            let product = products[i]


                            let previous_featured_image_id = 0

                            if (product.variants.length > 1) {
                                // check if image assigned to variants

                                let product_url = 'https://' + domain + '/products/' + product.handle

                                if (found_product_with_different_images == false || found_product_with_stock_out_option == false) {

                                    for (let j in product.variants) {

                                        let variant = product.variants[j]

                                        if (variant.featured_image && found_product_with_different_images == false) {

                                            var featured_image_id = variant.featured_image.id
                                            if (previous_featured_image_id == 0) {
                                                previous_featured_image_id = featured_image_id
                                            } else if (featured_image_id != previous_featured_image_id) {

                                                previous_featured_image_id = featured_image_id

                                                let product_list = 'product_with_different_images: <a href="' + product_url + '" target="_blank">' +
                                                    product.handle + '</a> <button class="btn btn-sm copy-btn" data-clipboard-text="' + product_url + '" >Copy link</button><br>'

                                                shopifyDomain.find('.products').append(product_list)
                                                $('[data-domain="' + domain + '"]').show()

                                                // console.log(product_list);

                                                found_product_with_different_images = true

                                                break;
                                            }
                                        }

                                        // find product_with_stock_out_option
                                        if (!variant.available && found_product_with_stock_out_option == false) {
                                            found_product_with_stock_out_option = true;

                                            let product_list = 'product_with_out_of_stock_variant: <a href="' + product_url + '?variant=' + variant.id + '" target="_blank">' +
                                                product.handle + '</a> <button class="btn btn-sm copy-btn" data-clipboard-text="' + product_url + '?variant=' + variant.id + '" >Copy link</button><br>'

                                            shopifyDomain.find('.products').append(product_list)
                                            $('[data-domain="' + domain + '"]').show()
                                        }

                                    }

                                }


                                if (found_product_with_swatch_options == false) {
                                    // check if options has color
                                    for (let k in product.options) {

                                        let option = product.options[k]

                                        if (option.values.length > 1) {

                                            var colors = ['couleur', 'color', 'colour', 'aspecto', 'finish', 'design', 'kleur', 'kolor', 'material', 'model', 'Opção', 'style', 'variant', 'cor']

                                            if ($.inArray(option.name.toLowerCase(), colors) >= 0) {


                                                let product_list = 'product_with_swatch_options: <a href="' + product_url + '" target="_blank">' +
                                                    product.handle + '</a> <button class="btn btn-sm copy-btn" data-clipboard-text="' + product_url + '" >Copy link</button><br>'

                                                shopifyDomain.find('.products').append(product_list)
                                                $('[data-domain="' + domain + '"]').show()


                                                // console.log(product_list);

                                                found_product_with_swatch_options = true

                                                break;
                                            }

                                        }

                                    }
                                }


                                if (found_product_with_more_options == false) {
                                    // check if options has color
                                    if (product.options.length > 1) {

                                        let product_list = 'product_with_atleast_2_options: <a href="' + product_url + '" target="_blank">' +
                                            product.handle + '</a> <button class="btn btn-sm copy-btn" data-clipboard-text="' + product_url + '" >Copy link</button><br>'

                                        shopifyDomain.find('.products').append(product_list)
                                        $('[data-domain="' + domain + '"]').show()

                                        // console.log(product_list);

                                        found_product_with_more_options = true

                                    }

                                }



                                // find VDK product
                                if (is_vdk) {
                                    if (found_vdk_product == false) {
                                        var accordion_selector = $(obj).data('settings')
                                        accordion_selector = '<' + accordion_selector + '>'
                                        var accordion_regex = new RegExp(accordion_selector, "i");

                                        let product_list = null

                                        if (accordion_regex.test(product.body_html)) {

                                            product_list = 'product_with_accordion_selector: <a href="' + product_url + '" target="_blank">' +
                                                product.handle + '</a> <button class="btn btn-sm copy-btn" data-clipboard-text="' + product_url + '" >Copy link</button><br>'

                                            found_vdk_product = true;
                                        } else if (findHashtags(product.body_html)) {

                                            product_list = 'product_with_hashtags: <a href="' + product_url + '" target="_blank">' +
                                                product.handle + '</a> <button class="btn btn-sm copy-btn" data-clipboard-text="' + product_url + '" >Copy link</button><br>'

                                            found_vdk_product = true;

                                        }


                                        if (found_vdk_product) {
                                            shopifyDomain.find('.products').append(product_list)
                                            $('[data-domain="' + domain + '"]').show()
                                        }


                                    }
                                } else {
                                    found_vdk_product = true;
                                }



                            }

                            if (found_product_with_swatch_options && found_product_with_different_images && found_vdk_product && found_product_with_more_options && found_product_with_stock_out_option) {
                                break
                            }
                        }

                    } else {
                        break;
                    }

                    if (found_product_with_swatch_options && found_product_with_different_images && found_vdk_product && found_product_with_more_options && found_product_with_stock_out_option) {
                        break
                    }

                    if (products.length < 30) {
                        break
                    }

                } else {
                    //alert("HTTP-Error: " + response.status);
                    $('[data-domain="' + domain + '"]').find('.bg-success').removeClass('bg-success').addClass('bg-danger')
                    $('[data-domain="' + domain + '"]').find('.products').html('<b>STORE NOT ACCESSIBLE. HTTP-Error: ' + response.status + ' </b>')
                    $('[data-domain="' + domain + '"]').show()
                    break;
                }

                page = page + 1
            }




        }
        // console.log(domain);
        // console.log(domains);
    })
}

function findHashtags(searchText) {
    var regexp = /\B\#\w\w+\b/g
    result = searchText.match(regexp);
    if (result) {
        // console.log(result);
        return true;
    } else {
        return false;
    }
}