export default function defaultVariant() {
  const vsk_apps = ["variant_swatch_king", "vsk_dev", "vsk_dev_1", "vsk_dev_2", "vsk_dev_3", "vsk_stage" ]
  $(".temp_information").each(async function (index, obj) {
    let vsk_data = $(obj)
      .data("temp")
      ?.filter((app) => vsk_apps.includes(app.app_code) );
    let store_with_aleast_one_grp = vsk_data[0]?.data?.filter(
      (store) => store.product_groups_count !== 0
    );
    let password_disable_store = store_with_aleast_one_grp?.filter(
      (store) => store?.password_enabled === false
    );
    console.log(
      password_disable_store?.length === 0
        ? " password enabled ...."
        : password_disable_store?.length > 0
        ? "fetching ...."
        : "no data"
    );
    let default_variant_found = false;
    password_disable_store?.map((store_data) => {
      let data_file = document.createElement("script");

      data_file.src = store_data?.data_url;
      data_file.onload = () => {
        const data_url_data = starapps_data["product_groups"];
        const is_data_url_data_arry = Array.isArray(data_url_data);
        console.log(is_data_url_data_arry);
        if (is_data_url_data_arry) {
          const grp_minimun_two_product = data_url_data?.filter(
            (grp) => grp["option_values"].length >= 2
          );
          grp_minimun_two_product?.map(async (grp_data) => {
            for (
              let index = 0;
              index < grp_data?.option_values.length;
              index++
            ) {
              const element = grp_data?.option_values[index];
              let url =
                "https://" +
                store_data?.shopify_domain +
                "/products/" +
                element["handle"] +
                ".json";

              const res = await fetch(url);
              if (res.ok) {
                var data = await res.json();

                if (default_variant_found === false) {
                  if (data.product.variants[0].title === "Default Title") {
                    let a = document.createElement("a");
                    const product_url =
                      "https://" +
                      store_data?.shopify_domain +
                      "/products/" +
                      data.product.handle;
                    let product_list =
                      'product_with_dummy_variant: <a href="' +
                      product_url +
                      ' " target="_blank">' +
                      data.product.handle +
                      '</a> <button class="btn btn-sm copy-btn" data-clipboard-text="' +
                      product_url +
                      '" >Copy link</button><br>';

                    $('[data-domain="' + store_data?.shopify_domain + '"]')
                      .find(".products")
                      .append(product_list);
                    $(
                      '[data-domain="' + store_data?.shopify_domain + '"]'
                    ).show();
                    default_variant_found = true;

                    break;
                  }
                }
              }
            }
          });
        } else {
        }
      };
      document.body.append(data_file);
    });
  });
}
