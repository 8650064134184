"use strict";

export default function themeAsset() {
    var app;
    var id;
    var theme_id;
    var key;
    var theme_store_id;
    var store_domain;
    $('.fetch-assets').on('click', async function(e) {
        e.stopPropagation();
        app = $(this).attr('data-app');
        id = $(this).attr('data-id');
        theme_id = $(this).attr('data-theme-id');

        $('.list-assets[data-theme="' + theme_id + '"]').toggle();

        // if already populated return
        if ($(this).attr("data-populated")) {
            return;
        }

        var url = '/themes/' + app + '/' + id + '/' + theme_id + '/assets';
        var response = await fetch(url);

        if (response.ok) {
            // if HTTP-status is 200-299
            // get the response body (the method explained below)
            var assets = await response.json();
            // console.log(assets);
            var html = '';

            for (var i in assets) {
                var asset = assets[i];
                html += '<div class="list-group-item list-group-item-action"><span>' + i + ': </span><a class="asset" data-theme-id="' + theme_id + '" data-key="' + asset.key + '">' + asset.key + '</a></div>';
            }

            $('.list-assets[data-theme="' + theme_id + '"]').html(html);

            // set populated attribute
            $(this).attr("data-populated", true);
        } else {
            alert("HTTP-Error: " + response.status); //break;
        }
    });
    $('body').on('click', '.asset', async function(e) {
        e.stopPropagation();
        theme_id = $(this).attr('data-theme-id');
        key = $(this).attr('data-key');
        var data = {
            key: key
        };
        var url = '/themes/' + app + '/' + id + '/' + theme_id + '/get_asset_content';
        var response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            var asset = await response.json();
            // console.log(asset);
            if (asset.success) {
                $('#asset-key').html(key);
                $('#save-asset').attr('data-key', key);
                $('#save-asset').attr('data-theme-id', theme_id);
                $('html, body').animate({
                    scrollTop: $("#asset_editor").offset().top
                }, 300);
                window.ThemeEditor.setValue(asset.code);
            } else {
                alert(asset.code);
            }
        } else {
            alert("HTTP-Error: " + response.status); //break;
        }
    });

    $('body').on('change', '#change_language', function(e) {
        window.ThemeEditor.session.setMode("ace/mode/" + $(this).val());
    });

    $('body').on('click', '#save-asset', async function(e) {
        e.stopPropagation();

        key = $(this).attr('data-key');
        theme_id = $(this).attr('data-theme-id');

        var value = window.ThemeEditor.getValue();
        var data = {
            key: key,
            value: value
        };
        var url = '/themes/' + app + '/' + id + '/' + theme_id + '/save_asset_content';
        var response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            var result = await response.json();
            // console.log(result);
            if (result.success == false) {
                alert(result.msg);
            } else if (result.success.updated_at) {
                var msg = 'SAVED!!' + "\n" + 'key:' + result.success.key + ', updated_at:' + result.success.updated_at;
                alert(msg);
            } else {
                alert(JSON.stringify(result.msg));
            }
        } else {
            alert("HTTP-Error: " + response.status); //break;
        }
    });

    $('.user-store-modal').on('click', async function(e) {
        e.preventDefault();
        theme_store_id = $(this).attr('data-theme-id');
        store_domain = $(this).attr('data-store-domain');
        id = $(this).attr('data-id');
        app = $(this).attr('data-app');

        $('.user-store-modal').addClass('active');
        $('#user-store-modal.modal').css("display","block");
        $('#user-store-modal.modal').css("opacity","1");

    });

    $('#user-store-modal .close').on('click', async function(e) {
        e.preventDefault();
        $('.user-store-modal').removeClass('active');
        $('#user-store-modal.modal').css("display","none");
        $('#user-store-modal.modal').css("opacity","0");
    });

    $('#user-store-modal .btn-clone').on('click', async function(e) {
        e.preventDefault();
        $(this).attr('disabled', true);
        let user_store = $('#user_store').find(":selected").val();
        let url = '/themes/' + app + '/' + id + '/clone/' + theme_store_id;

        console.log("store_name :: ", store_domain.split('.myshopify.com')[0]);
        fetch(url,
            {method: 'POST',
            body: JSON.stringify({'store_name': store_domain.split('.myshopify.com')[0], 'user_store_id': user_store})
        }).then(function(res){
            return res.text();
        }).then(function(response){
            let resp = JSON.parse(response);
            $('.user-store-modal').removeClass('active');
            $('#user-store-modal.modal').css("display","none");
            $('#user-store-modal.modal').css("opacity","0");
            if(resp.success) {
                $('#theme-clone-alert').html(resp.message)
                $('#theme-clone-alert').addClass('alert-success text-success')
            }else {
                $('#theme-clone-alert').html(resp.message)
                $('#theme-clone-alert').addClass('alert-danger text-danger')
            }
            $('.btn-clone').attr('disabled', false);
            setTimeout(function() {
                $('#theme-clone-alert').html('')
                $('#theme-clone-alert').removeClass('alert-success text-success alert-danger text-danger')
            }, 3000)
        });

    });

};