
export default function activeGroups() {
    const vsk_apps = ["variant_swatch_king", "vsk_dev", "vsk_dev_1", "vsk_dev_2", "vsk_dev_3", "vsk_stage",  ]
    $('.temp_information').each(async function(index, obj) {
        let  vsk_data = $(obj).data('temp')?.filter(app =>  vsk_apps.includes(app.app_code))
        if(vsk_data[0]?.data){
            vsk_data[0]?.data?.map(store=>{
                if(store.group_active){
                    active_group_products = JSON.parse(store.group_active)
                    console.log(active_group_products,"active_group_products")
                    if(active_group_products !== null && active_group_products.length){
                        const product_url = 'https://' + store?.shopify_domain + "/products/" + active_group_products[0].handle
                        let product_list = 'product_in_active_group: <a href="' + product_url + ' " target="_blank">' +
                        active_group_products[0].handle+ '</a> <button class="btn btn-sm copy-btn" data-clipboard-text="' + product_url + '" >Copy link</button><br>'
                        $('[data-domain="' + store?.shopify_domain + '"][data-app_name="VSK"]').find('.products').append(product_list)
                        $('[data-domain="' + store?.shopify_domain + '"][data-app_name="VSK"]').show()
                    }
                }
                if(store?.product_in_group_url)
                {
                    let product_with_group = 'product_with_group: <a href="' + store?.product_in_group_url + ' " target="_blank">' +
                    store.product_in_group_url + '</a> <button class="btn btn-sm copy-btn" data-clipboard-text="' + store.product_in_group_url + '" >Copy link</button><br>'
                    $('[data-domain="' +  store?.shopify_domain + '"][data-app_name="VSK"]').find('.products').append(product_with_group)
                    $('[data-domain="' +  store?.shopify_domain + '"][data-app_name="VSK"]').show()
                }

               console.log(store.group_active !== null,"store.group_active !== null)")
            })
        }
    })
}